import { Spinner } from "@shopify/polaris";
import styled from "styled-components";

const SpinnerWrapper = styled.div<{ height?: string }>`
  height: ${props => props.height || "70svh"};
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * Horizontally and vertically centers a Polaris Spinner without having to fuss with Polaris stacks
 * @param height - The height of the spinner wrapper
 * @param size - The size of the spinner, defaults to "large"
 */
export function CenterSpinner({
  height,
  size = "large",
}: {
  height?: string;
  size?: "small" | "large";
}): JSX.Element {
  return (
    <SpinnerWrapper height={height}>
      <Spinner size={size} />
    </SpinnerWrapper>
  );
}
